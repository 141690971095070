import React from "react";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, Inline as RichTextInline, Block as RichTextBlock, Text as RichTextText, INLINES } from "@contentful/rich-text-types";
import { MainCategoryHeading } from "../Atoms/CategoryHeading";
import { css } from "@emotion/react";
import { Heading3, Heading5 } from "../Atoms/Text";
import { bpLarge, bpMedium, colorLight } from "../../stylingConstants";
import BulletList, { BulletListItem } from "../Atoms/BulletList";
import Blockquote from "../Atoms/Blockquote";
import { horizontalMargin, verticalMargin } from "../../margins";
import TextLink from "../Atoms/TextLink";
import VideoEmbed from "../Atoms/VideoEmbed";
import { contentLargeWidth, contentSmallWidth } from "./ContentPageLayout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { v4 as uuidv4 } from "uuid";

type ContentfulRichTextProps = {
  json: Document; // this is the correct type
};

type RichTextNode = RichTextInline | RichTextBlock | RichTextText;

const inlineAndTextTypes = ["text", INLINES.ASSET_HYPERLINK, INLINES.EMBEDDED_ENTRY, INLINES.ENTRY_HYPERLINK, INLINES.HYPERLINK];

const nodeIsInline = (node: RichTextNode): node is RichTextText | RichTextInline => {
  return inlineAndTextTypes.indexOf(node.nodeType) !== -1;
};

const extractInlineChildren = (node: RichTextNode, acc: (RichTextText | RichTextInline)[] = []): (RichTextText | RichTextInline)[] => {
  if (nodeIsInline(node)) {
    acc.push(node);
    return acc;
  }
  (node.content as RichTextNode[]).forEach((childNode) => extractInlineChildren(childNode, acc));
  return acc;
};

const renderInlineOnly = (node: RichTextNode) =>
  documentToReactComponents({
    nodeType: BLOCKS.DOCUMENT,
    //@ts-ignore, sending Textblocks here is fine.
    content: extractInlineChildren(node),
    data: {},
  });

export default function ContentfulRichText({ json }: any) {
  // Optionally, you can define options for customizing how different types of nodes are rendered
  const options: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => <MainCategoryHeading>{children}</MainCategoryHeading>,
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p
          css={css`
            white-space: pre-wrap;
          `}
        >
          {children}
        </p>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Heading3
          css={css`
            margin-top: 38px;
            margin-bottom: 28px;
            @media ${bpMedium} {
              margin-top: 64px;
            }
            @media ${bpLarge} {
              margin-top: 100px;
              margin-bottom: 52px;
            }
          `}
        >
          {children}
        </Heading3>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <Heading5
          css={css`
            color: ${colorLight};
            margin-bottom: -8px;
            margin-top: 18px;
            @media ${bpLarge} {
              margin-top: 22px;
            }
          `}
        >
          {children}
        </Heading5>
      ),
      [BLOCKS.UL_LIST]: (_node, children) => <BulletList>{children}</BulletList>,
      [BLOCKS.LIST_ITEM]: (node) => <BulletListItem>{renderInlineOnly(node)}</BulletListItem>,
      [BLOCKS.QUOTE]: (node) => (
        <Blockquote
          css={css`
            ${verticalMargin(20)}
          `}
        >
          {renderInlineOnly(node)}
        </Blockquote>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const slugValue: string | undefined = node.data?.target?.slug;
        if (!slugValue) {
          return null;
        }
        return <TextLink to={slugValue.startsWith("mailto") || slugValue.startsWith("tel") ? slugValue : `/${slugValue}`}>{children}</TextLink>;
      },
      [INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
        return <TextLink to={uri}>{children}</TextLink>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const contentType = node.data.target?.__typename;
        switch (contentType) {
          case "ContentfulEmbeddedVideo": {
            const { url, thumbnail } = node.data.target as {
              url: string;
              thumbnail?: { file?: { url?: string } };
            };
            return <EmbeddedVideoEmbed url={url} thumbnail={thumbnail?.file?.url} />;
          }
          case "ContentfulImageGroup": {
            const { images, layout } = node.data.target as {
              layout?: "From left" | "From right";
              images?: { file?: { url?: string } }[];
            };
            return (
              <div
                css={css`
                  @media ${bpMedium} {
                    height: ${(images?.length ?? 0) * 255}px;
                  }
                `}
              >
                {images
                  ?.map((node) => node.file?.url)
                  .filter((url) => url)
                  .map((url, index) => {
                    const toTheLeft = (index + (layout === "From left" ? 1 : 0)) % 2 > 0 ? true : false;
                    return (
                      <span key={uuidv4()}>
                        <figure
                          key={uuidv4()}
                          css={css`
                            width: 100%;
                            ${horizontalMargin(0)}
                            ${verticalMargin(24)}
                            @media ${bpMedium} {
                              ${verticalMargin(64)}
                              position: absolute;
                              max-width: 48%;
                              ${{ [toTheLeft ? "left" : "right"]: 0 }}
                              height: 300px;
                              display: flex;
                              flex-direction: row;
                              justify-content: ${toTheLeft ? "flex-end" : "flex-start"};
                              align-items: center;
                            }
                          `}
                        >
                          <img
                            src={`${url}?h=300`}
                            css={css`
                              object-fit: contain;
                              max-height: 100%;
                              max-width: 100%;
                            `}
                          />
                        </figure>
                        <div
                          css={css`
                            display: none;
                            @media ${bpMedium} {
                              display: block;
                              height: 100px;
                            }
                          `}
                        />
                      </span>
                    );
                  })}
              </div>
            );
          }
          default:
            return null;
        }
      },
    },
  };

  const EmbeddedVideoEmbed = ({ url, thumbnail }: { url: string; thumbnail?: string }) => (
    <VideoEmbed
      src={url}
      thumbSrc={thumbnail}
      css={css`
        width: 100%;
        height: ${(contentSmallWidth / 16) * 9}px;
        ${verticalMargin(12)}
        @media ${bpMedium} {
          ${verticalMargin(18)}
          height: ${(contentLargeWidth / 16) * 9}px;
        }
        @media ${bpLarge} {
          ${verticalMargin(24)}
        }
      `}
    />
  );
  return <>{renderRichText(json, options)}</>;
}
